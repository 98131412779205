import React from "react";
import { useState, useEffect, useRef } from "react";
import dataTemplate from "./data/dataTemplate.js";
import { templates } from "./data/template.js";
import bg from "./images/DirectorateBG2.jpg";
import r7_img from "./images/logo.png";
import bfp7_img from "./images/bfplogo.png";
import Card from "./components/Personnel";
import Select from "react-select";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import { ToastContainer, toast } from "react-toastify";
import getProfileData from "./service/profileService.js";
import { getFormattedAllStationData } from "./service/stationService.js";
import {
  faDownload,
  faPenToSquare,
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { region, city, municipal } from "./data/classTemplate.js";

function Create() {
  const [personnel, setPersonnel] = useState(dataTemplate.region);
  const [currentPersonnel, setCurrentPersonnel] = useState();
  const [stationList, setStationList] = useState([]);
  const [selectedStation, setSelectedStation] = useState({
    value: "-1",
    label: "Click to edit station name"
  });
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [effectCount, setEffectCount] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [toggleStation, setToggleStation] = useState(false);
  const [stationName, setStationName] = useState("Click to edit station name");
  const [classSelector, setClassSelector] = useState("region");

  const domEl = useRef(null);
  const mainEl = useRef(null);

  useEffect(() => {
    fetchProfile();
  }, [personnel, currentPersonnel]);
  useEffect(() => {
    fetchStation();
  }, []);

  const fetchProfile = async () => {
    if (effectCount === 0 && currentPersonnel) {
      setEffectCount((effectCount) => [effectCount, effectCount + 1]);
      let dataId = await toast.promise(
        getProfileData({
          accountno: currentPersonnel.accountno
        }),
        {
          pending: "Fetching Profile Data.",
          success: "Fetched Profile Data Successfully.",
          error: "Fetching Data Rejected"
        }
      );
      if (Object.keys(dataId).length > 0) {
        const updatedItems = [...personnel];
        updatedItems[currentPersonnel.id] = {
          ...updatedItems[currentPersonnel.id],
          accountno: dataId.accountno,
          name: dataId.name,
          image: dataId.image
        };
        setPersonnel(updatedItems);
        toast.success("Personnel Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      } else {
        toast.error("Personnel Not Found.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      }
    }
  };

  const fetchStation = async () => {
    let dataId = await toast.promise(getFormattedAllStationData(), {
      pending: "Fetching Station Data.",
      success: "Fetched Station Data Successfully.",
      error: "Fetching Data Rejected"
    });
    dataId.stationData.push({
      label: "Office of the Regional Director",
      value: dataId.stationData.length,
      phone: "(032) 517-0927"
    });
    setStationList(dataId.stationData);
  };

  const addToDirectory = (cardVal) => {
    setEffectCount(0);
    const index = personnel.findIndex(
      (person) => person.id === cardVal.getValues.id
    );
    const updatedItems = [...personnel];
    updatedItems[index] = {
      ...updatedItems[index],
      accountno: cardVal.getValues.accountNumber
    };
    setCurrentPersonnel(updatedItems[index]);
    setPersonnel(updatedItems);
  };

  const clearHandler = (i) => {
    if (i > 1) {
      const index = personnel.findIndex((person) => person.id === i);
      const updatedItems = [...personnel];
      updatedItems[index] = {
        ...updatedItems[index],
        accountno: ""
      };
      setPersonnel(updatedItems);
    }
  };

  const downloadImage = async () => {
    const dataUrl = await toast
      .promise(htmlToImage.toJpeg(domEl.current), {
        pending: "Generating Directorate.",
        success: "Generated Successfully.",
        error: "Download Failed"
      })
      .catch((error) => {
        alert(error);
      });
    const link = document.createElement("a");
    link.download = "html-to-img.jpg";
    link.href = dataUrl;
    link.click();
    previewHandler();
  };

  const handleCreateImage = async () => {
    const element = document.getElementById("domEl");
    const canvas = await toast
      .promise(
        html2canvas(element, {
          width: 5400,
          height: 10800,
          x: 0,
          y: 0,
          scale: 0.5,
          useCORS: true
        }),
        {
          pending: "Generating Directorate.",
          success: "Generated Successfully.",
          error: "Download Failed"
        }
      )
      .catch((error) => {
        alert(error);
      });
    const data = canvas.toDataURL("image/jpg", 1);
    const link = document.createElement("a");
    link.download = "html-to-img.jpg";
    link.href = data;
    link.click();
    previewHandler();
  };

  const previewHandler = () => {
    setIsDownloadable((current) => !current);
  };

  const handleChange = (selectedOption) => {
    setSelectedStation(selectedOption);
  };
  const handleTemplate = (selectedOption) => {
    if (selectedOption.label === "region") {
      setPersonnel(dataTemplate.region);
      classTemplate = region.basic;
      setClassSelector("region");
    } else if (selectedOption.label === "city") {
      setPersonnel(dataTemplate.city);
      classTemplate = region.city;
      setClassSelector("city");
    } else if (selectedOption.label === "municipal") {
      setPersonnel(dataTemplate.municipal);
      classTemplate = region.municipal;
      setClassSelector("municipal");
    }
  };

  // let divWidthClass =
  //   "flex-col bg-cover bg-no-repeat pointer-events-auto xl:items-center xl:w-1000 xl:h-1500 xl:pt-4 md:w-1000 md:h-1500 md:pt-4 sm:w-1000 sm:h-1500 sm:pt-4 xs:w-1000 xs:pt-4 xs:h-1500 mobile:h-1500 mobile:w-1000 mobile:pt-4";
  // let mainDivClass =
  //   "h-hero p-4 xl:w-8/12 xl:flex-row mobile:w-full xl:justify-center md:justify-center";
  // let cardDivClass =
  //   "pb-10 m-0 md:h-1500 md:w-8/12 sm:w-5/12 xs:w-5/12 mobile:w-7/12";
  // let headerMarginClass = "mb-2";
  // let stationClass =
  //   "font-bold text-center md:text-4xl mobile:text-xl mobile:px-4";
  // let hotlineClass = " md:pb-6 md:text-3xl mobile:text-xs mobile:pb-4";
  // let directoryClass = "md:text-5xl mobile:text-2xl";
  // let strokeClass = "0.4px";
  // let hotlineStroke = "0.4px";
  // let toggleMask = "opacity-0 h-1500 invisible";
  let classTemplate = region.basic;
  let currentClass = "region";
  if (isDownloadable) {
    if (classSelector == "region") {
      classTemplate = region.downloadable;
    } else if (classSelector == "city") {
      classTemplate = city.downloadable;
    } else if (classSelector == "municipal") {
      classTemplate = municipal.downloadable;
    }

    // divWidthClass = "pt-40 bg-cover w-5400 h-10800 pointer-events-none";
    // mainDivClass = "border h-hero p-4 xl:w-8/12 mobile:w-full";
    // cardDivClass = "h-10800 w-7/12 pb-32";
    // stationClass = "text-center text-22xl font-bold";
    // hotlineClass = "text-12xl font-bold pb-12";
    // directoryClass = "text-22xl font-bold mb-72";
    // toggleMask = "opacity-100 h-10800 visible";
    // strokeClass = "15px";
    // hotlineStroke = "10px";
  }
  return (
    <div className="flex w-screen items-start justify-center xl:flex-row md:flex-col mobile:flex-col sm:flex-col">
      <div className="flex flex-col xl:px-16 mobile:px-4 py-4 xl:w-4/12 md:w-full mobile:w-full">
        <span className="text-lg mb-4 font-semibold text-yellow-500 ">
          Basic usage:
        </span>
        <div className="flex flex-col mb-2">
          <span className="sm:text-2xl mobile:text-lg font-semibold text-black">
            Selecting Template
          </span>
          <div className="flex flex-col ps-4">
            <span className="sm:text-base mobile:text-xs font-light ">
              • Select the appropriate template on the control box below
            </span>
          </div>
        </div>
        <div className="flex flex-col mb-2">
          <span className="sm:text-2xl mobile:text-lg font-semibold text-black">
            Adding Personnel
          </span>
          <div className="flex flex-col ps-4">
            <span className="sm:text-base mobile:text-xs font-light ">
              • Hover on the empty template
            </span>
            <span className="sm:text-base mobile:text-xs font-light ">
              • You can click on the Edit Button on the upper right corner of
              the template
            </span>
            <span className="sm:text-base mobile:text-xs font-light ">
              • Enter the Account Number of the personnel and press ENTER
            </span>
          </div>
        </div>
        <div className="flex flex-col mb-2">
          <span className="sm:text-2xl mobile:text-lg font-semibold text-black">
            Removing Personnel
          </span>
          <div className="flex flex-col ps-4">
            <span className="sm:text-base mobile:text-xs font-light ">
              • Hover on the empty template
            </span>
            <span className="sm:text-base mobile:text-xs font-light ">
              • You can click on the X or Clear Button on the upper right corner
              of the template
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="sm:text-2xl mobile:text-lg font-semibold text-black">
            Finalize and Download
          </span>
          <div className="flex flex-col ps-4">
            <span className="sm:text-base mobile:text-xs font-light ">
              • Click on the Finalize Directory to get the Standard Size of the
              Directory
            </span>
            <span className="sm:text-base mobile:text-xs font-light ">
              • A Download button will appear and Click it to download the
              Directory
            </span>
            <span className="sm:text-base mobile:text-xs font-light ">
              • Wait until the Directorate has been Generated successfully
            </span>
            <span className="sm:text-base mobile:text-xs font-light ">
              • Download prompt will appear and you can rename your file as you
              want and download it
            </span>
          </div>
        </div>
        <div className="flex flex-col p-2 border justify-center items-center mt-4 xl:w-full">
          <div className="flex flex-col justify-center xl:w-full md:w-5/12 sm:w-8/12 mobile:w-full">
            <span className="sm:text-2xl mobile:text-lg font-semibold text-black">
              Select Template
            </span>
            <Select
              className="basic-single w-full md:text-lg mobile:text-xs mobile:h-mobile"
              classNamePrefix="selectTemplate"
              defaultValue={templates || ""}
              isSearchable={true}
              name="data"
              onChange={handleTemplate}
              options={templates}
            />
          </div>

          <div className="flex flex-col grow-0 items-center w-full mt-4 ">
            <div className="flex flex-col xl:w-full md:w-5/12 sm:w-8/12 mobile:w-full">
              {isDownloadable ? (
                <div className="flex w-full justify-center space-x-4">
                  <button
                    className="p-2 rounded-md bg-yellow-500 inline-flex flex-row-reverse space-x-2"
                    onClick={previewHandler}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="text-xl text-black ms-2"
                    />

                    <span>Edit Directory</span>
                  </button>
                  <button
                    className="p-2 rounded-md bg-green-800 text-white inline-flex flex-row-reverse space-x-2"
                    onClick={handleCreateImage}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="text-xl text-white ms-2"
                    />
                    <span>Download Directory</span>
                  </button>
                </div>
              ) : (
                <button
                  className="p-2 rounded-md bg-yellow-500 inline-flex items-center justify-center flex-row-reverse space-x-2"
                  onClick={previewHandler}
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-xl text-black ms-2"
                  />
                  <span>Finalize Directory</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="mainDiv"
        ref={mainEl}
        className={`flex ${classTemplate.mainDivClass} bg-gray-100 grow-0 shrink-0 overflow-x-scroll overflow-y-scroll scroll-smooth`}
      >
        <div className="relative">
          <div
            id="domEl"
            ref={domEl}
            className={`flex flex-col items-center ${classTemplate.divWidthClass} shadow-2xl rounded-xl`}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + bg})`,
              objectPosition: "center"
            }}
          >
            <div
              className={` flex flex-col w-full justify-center items-center ${classTemplate.headerMarginClass} `}
            >
              {stationList.length > 0 && toggleStation ? (
                <div className="edit-station flex space-x-2 items-center md:w-8/12 sm:w-7/12 xs:w-6/12 mobile:w-9/12">
                  <Select
                    className="basic-single w-full md:text-lg mobile:text-xs mobile:h-mobile"
                    classNamePrefix="select"
                    defaultValue={stationList || ""}
                    isSearchable={true}
                    name="stationData"
                    onChange={handleChange}
                    options={stationList}
                  />
                  <button
                    className="rounded-full bg-white"
                    onClick={() => setToggleStation(!toggleStation)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-8 h-8 text-green-500"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                ""
              )}
              {!toggleStation ? (
                <div className="flex flex-col group items-center justify-center hover:border-2 cursor-pointer">
                  <span
                    className={`${classTemplate.stationClass} uppercase font-bold text-blue-800 drop-shadow-xl`}
                    onClick={() => setToggleStation(!toggleStation)}
                    style={{
                      WebkitTextStrokeWidth: `${classTemplate.strokeClass}`,
                      WebkitTextStrokeColor: "white"
                    }}
                  >
                    {/* {selectedStation.label.length > 39
                      ? selectedStation.label
                          .substring(0, selectedStation.label.indexOf(","))
                          .trim()
                      : selectedStation.label} */}
                    {selectedStation.label}
                  </span>
                  {/* <span
                    className={`${stationClass} uppercase font-bold text-red-600 drop-shadow-xl `}
                    onClick={() => setToggleStation(!toggleStation)}
                    style={{
                      WebkitTextStrokeWidth: `${strokeClass}`,
                      WebkitTextStrokeColor: "white",
                    }}
                  >
                    {selectedStation.label.length > 39
                      ? selectedStation.label
                          .substring(
                            selectedStation.label.indexOf(",") + 1,
                            selectedStation.label.length
                          )
                          .trim()
                      : ""}
                    {selectedStation.label}
                  </span> */}
                </div>
              ) : (
                ""
              )}

              <span
                className={`${classTemplate.hotlineClass} text-black font-bold`}
                style={{
                  WebkitTextStrokeWidth: `${classTemplate.hotlineStroke}`,
                  WebkitTextStrokeColor: "white"
                }}
              >
                Hotline: {selectedStation.phone}
              </span>
              <div
                className={`${classTemplate.directoryGroupClass} flex items-center`}
              >
                <div className={`${classTemplate.directoryIconLeftClass}`}>
                  <img src={bfp7_img} alt="" />
                </div>

                <span
                  className={`${classTemplate.directoryClass} text-red-600 font-bold uppercase drop-shadow-xl `}
                  style={{
                    WebkitTextStrokeWidth: `${classTemplate.strokeClass}`,
                    WebkitTextStrokeColor: "white"
                  }}
                >
                  Directory
                </span>
                <div className={`${classTemplate.directoryIconRightClass}`}>
                  <img src={r7_img} alt="" className="w-full h-auto" />
                </div>
              </div>
            </div>
            {personnel && (
              <div className={`flex flex-col ${classTemplate.cardDivClass} `}>
                {personnel.map((p, i) => {
                  return (
                    <div key={i}>
                      <Card
                        personnel={p}
                        clearHandler={clearHandler}
                        userDesignation={p.designation}
                        isDownloadable={isDownloadable}
                        handleClick={addToDirectory}
                        index={i}
                        classHandler={classSelector}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className={`flex flex-col w-full bg-black/90 ${classTemplate.toggleMask} absolute top-0 left-0 -z-1`}
          >
            <div className="flex flex-col justify-start items-start w-full mt-20 p-20 bg-green-800">
              <FontAwesomeIcon
                icon={faDownload}
                className="text-8xl text-white"
              />
              <span className="w-full mt-8 text-start text-4xl text-white -z-2">
                Download Ready
              </span>
              <span className="w-full font-thin text-start text-2xl text-white -z-2">
                Click the button on the panel
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Create;
