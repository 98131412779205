const BASE_URL = "https://itcusys.online/android/_getStation.php";

export const getStationData = (searchParams) => {
  const url = new URL(BASE_URL);
  url.search = new URLSearchParams({ ...searchParams });
  return fetch(url, { mode: "cors" })
    .then((res) => res.json())
    .catch((rejected) => console.log(rejected));
};

export const getAllStationData = () => {
  const url = new URL(BASE_URL);
  return fetch(url, { mode: "cors" })
    .then((res) => res.json())
    .catch((rejected) => console.log(rejected));
};

const formatStationData = (data) => {
  if (data != null && data.length > 0) {
    const stationData = data.map((item) => {
      const value = item.id != null ? item.id : "None";
      const label =
        item.assignment_name != null ? item.assignment_name : "None";
      const phone = item.phone_1 != null ? item.phone_1 : "";
      return {
        label,
        value,
        phone,
      };
    });
    return { stationData };
  }
};

export const getFormattedAllStationData = async () => {
  const formattedStationData = await getAllStationData().then(
    formatStationData
  );
  return { ...formattedStationData };
};
