import React, { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import imgBg from "../images/imgBg2.jpg";
import { region, city, municipal } from "../data/classCardTemplate";

function Card({
  personnel: { accountno, name, designation, image, id },
  userDesignation,
  clearHandler,
  isDownloadable,
  handleClick,
  index,
  classHandler,
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [accountNumber, setNumber] = useState("");
  const editHandler = () => {
    if (index > 1) {
      setIsEditable((current) => !current);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClick({ getValues });
    editHandler();
  };

  const getValues = {
    accountNumber: accountNumber,
    designation: designation,
    id: index,
  };
  let classCardTemplate = region.basic;
  if (classHandler === "region") {
    classCardTemplate = region.basic;
  } else if (classHandler === "city") {
    classCardTemplate = city.basic;
  } else if (classHandler === "municipal") {
    classCardTemplate = municipal.basic;
  }

  if (isDownloadable) {
    if (classHandler === "region") {
      classCardTemplate = region.downloadable;
    } else if (classHandler === "city") {
      classCardTemplate = city.downloadable;
    } else if (classHandler === "municipal") {
      classCardTemplate = municipal.downloadable;
    }
  }

  return (
    <div
      id="cardMain"
      className={`flex w-full border justify-center bg-white ${classCardTemplate.cardMain} shadow-md`}
    >
      <div
        id="cardContainer"
        className={`flex items-center w-full ${classCardTemplate.cardContainer}`}
      >
        <div
          id="cardImgContainer"
          className={`flex items-center justify-center ${classCardTemplate.cardImgContainer} bg-cover bg-no-repeat`}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + imgBg})` }}
        >
          {accountno ? (
            <div className={`${classCardTemplate.imageContainerClass}`}>
              <img
                src={image}
                alt="square"
                className={`w-full h-full rounded-full border-solid ${classCardTemplate.imageClass} border-white shadow-md`}
              />
            </div>
          ) : (
            <div className={`${classCardTemplate.imageContainerClass}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.4}
                stroke="currentColor"
                className="w-full h-full text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
          )}
        </div>
        <div
          className={`flex flex-col w-full h-full ${classCardTemplate.controlContainer} items-center justify-center`}
        >
          <div
            className={`flex w-full justify-end group duration-150 bg-white ${classCardTemplate.controlContainer}`}
          >
            <div className="flex justify-center items-center invisible group-hover:visible">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`${classCardTemplate.iconClass} text-red-800 cursor-pointer hover:scale-110`}
                onClick={editHandler}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </div>
            <XCircleIcon
              className={`${classCardTemplate.iconClass} text-red-800 invisible group-hover:visible cursor-pointer hover:scale-110`}
              onClick={() => (accountno ? clearHandler(index) : "")}
            />
          </div>
          <div className="flex flex-col w-full h-full justify-center items-center">
            {isEditable ? (
              <div className="flex w-full items-center justify-center md:mb-2 mobile:mb-0">
                <form
                  onSubmit={handleSubmit}
                  className="md:w-7/12 mobile:w-10/12"
                >
                  <div className="flex flex-col w-full bg-white shadow-sm rounded-md">
                    <input
                      id="accountNumber"
                      value={accountNumber}
                      onChange={(e) => setNumber(e.target.value)}
                      type="text"
                      placeholder="E.g. Account Number: A1234"
                      className="xl:text-sm/[8px] md:text-sm/[8px] sm:text-xs xs:text-xs mobile:text-xs mobile:p-1 rounded-md placeholder-slate-400 p-2 text-start border border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                    />
                  </div>
                </form>
              </div>
            ) : (
              <p
                className={`${classCardTemplate.accountNumberClass} font-semibold text-center`}
              >
                {accountno ? name : "Edit name"}
              </p>
            )}
            <p
              className={`${classCardTemplate.designationClass} font-light text-center`}
            >
              {userDesignation ? userDesignation : designation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
