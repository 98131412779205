const BASE_URL = "https://itcusys.online/android/_getProfile.php";
const IMAGE_URL = "https://itcusys.online/ppms_uploads/profile_img/";

const getProfileData = (searchParams) => {
  const url = new URL(BASE_URL);
  url.search = new URLSearchParams({ ...searchParams });
  return fetch(url, { mode: "cors" }).then((res) => res.json());
};

const formatProfileData = (data) => {
  if (data != null) {
    if (data.length > 0) {
      const {
        accountno,
        rank,
        firstname,
        middlename,
        lastname,
        qualifier,
        img,
      } = data[0];
      const name =
        rank +
        " " +
        firstname +
        " " +
        middlename.charAt(0) +
        " " +
        lastname +
        " " +
        qualifier;

      const image = IMAGE_URL + img;
      return {
        accountno,
        name,
        image,
      };
    }
  }
};

const getFormattedProfileData = async (searchParams) => {
  const formattedProfileData = await getProfileData(searchParams).then(
    formatProfileData
  );
  return { ...formattedProfileData };
};

export default getFormattedProfileData;
