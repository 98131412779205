export const region = [
  {
    id: 0,
    accountno: "bc",
    name: "DIRECTOR LOUIE S PURACAN, CEO VI",
    designation: "Chief, Bureau of Fire Protection",
    image: "https://itcusys.online/ppms_uploads/profile_img/d1.jpg"
  },
  {
    id: 1,
    accountno: "A96025",
    name: "CSUPT ROY RODERICK P AGUTO",
    designation: "Regional Director",
    image: "https://itcusys.online/ppms_uploads/profile_img/2957.jpg"
  },
  {
    id: 2,
    accountno: "",
    name: "",
    designation: "ARD for Administration",
    image: ""
  },
  {
    id: 3,
    accountno: "",
    name: "",
    designation: "ARD for Operation",
    image: ""
  },
  {
    id: 4,
    accountno: "",
    name: "",
    designation: "Regional Chief of Staff",
    image: ""
  },
  {
    id: 5,
    accountno: "",
    name: "",
    designation: "Chief, Regional Health Services Section",
    image: ""
  },
  {
    id: 6,
    accountno: "",
    name: "",
    designation: "Chief, Special Rescue Force",
    image: ""
  },
  {
    id: 7,
    accountno: "",
    name: "",
    designation: "Chief, Regional Legal Unit",
    image: ""
  },
  {
    id: 8,
    accountno: "",
    name: "",
    designation: "Chief, Regional Operations Division",
    image: ""
  },
  {
    id: 9,
    accountno: "",
    name: "",
    designation: "Chief, Fire Safety Enforcement Division",
    image: ""
  },
  {
    id: 10,
    accountno: "",
    name: "",
    designation: "Chief, Regional Administrative Division",
    image: ""
  },
  {
    id: 11,
    accountno: "",
    name: "",
    designation: "Chief, Plans and Programs Division",
    image: ""
  },
  {
    id: 12,
    accountno: "",
    name: "",
    designation: "Chief, Financial Management Division",
    image: ""
  },
  {
    id: 13,
    accountno: "",
    name: "",
    designation: "Chief, Regional Logistics Division",
    image: ""
  },
  {
    id: 14,
    accountno: "",
    name: "",
    designation: "Chief, ITCU",
    image: ""
  },
  {
    id: 15,
    accountno: "",
    name: "",
    designation: "Chief, Regional Internal Affair Services",
    image: ""
  },
  {
    id: 16,
    accountno: "",
    name: "",
    designation: "Chief, Regional Hearing Office",
    image: ""
  },
  {
    id: 17,
    accountno: "",
    name: "",
    designation: "Admin NCO, Chaplain Unit",
    image: ""
  },
  {
    id: 18,
    accountno: "",
    name: "",
    designation: "Regional Executive Senior Fire Officer",
    image: ""
  }
];

export const city = [
  {
    id: 0,
    accountno: "bc",
    name: "DIRECTOR LOUIE S PURACAN, CEO VI",
    designation: "Chief, Bureau of Fire Protection",
    image: "https://itcusys.online/ppms_uploads/profile_img/d1.jpg"
  },
  {
    id: 1,
    accountno: "A96025",
    name: "CSUPT ROY RODERICK P AGUTO",
    designation: "Regional Director",
    image: "https://itcusys.online/ppms_uploads/profile_img/2957.jpg"
  },
  {
    id: 2,
    accountno: "",
    name: "",
    designation: "Marshal",
    image: ""
  },
  {
    id: 3,
    accountno: "",
    name: "",
    designation: "Deputy Fire Marshal",
    image: ""
  },
  {
    id: 4,
    accountno: "",
    name: "",
    designation: "Chief Admin",
    image: ""
  },
  {
    id: 5,
    accountno: "",
    name: "",
    designation: "Chief Logistics",
    image: ""
  },
  {
    id: 6,
    accountno: "",
    name: "",
    designation: "Chief Fire Safety Enforcement Section",
    image: ""
  },
  {
    id: 7,
    accountno: "",
    name: "",
    designation: "Chief Plans and Trainings",
    image: ""
  },
  {
    id: 8,
    accountno: "",
    name: "",
    designation: "Chief Investigator",
    image: ""
  }
];

export const municipal = [
  {
    id: 0,
    accountno: "bc",
    name: "DIRECTOR LOUIE S PURACAN, CEO VI",
    designation: "Chief, Bureau of Fire Protection",
    image: "https://itcusys.online/ppms_uploads/profile_img/d1.jpg"
  },
  {
    id: 1,
    accountno: "A96025",
    name: "CSUPT ROY RODERICK P AGUTO",
    designation: "Regional Director",
    image: "https://itcusys.online/ppms_uploads/profile_img/2957.jpg"
  },
  {
    id: 2,
    accountno: "",
    name: "",
    designation: "Marshal",
    image: ""
  },
  {
    id: 3,
    accountno: "",
    name: "",
    designation: "Chief Admin",
    image: ""
  },
  {
    id: 4,
    accountno: "",
    name: "",
    designation: "Chief Logistics",
    image: ""
  },
  {
    id: 5,
    accountno: "",
    name: "",
    designation: "Chief Fire Safety Enforcement Section",
    image: ""
  },
  {
    id: 6,
    accountno: "",
    name: "",
    designation: "Chief Plans and Trainings",
    image: ""
  },
  {
    id: 7,
    accountno: "",
    name: "",
    designation: "Chief Investigator",
    image: ""
  }
];

export default {
  city,
  region,
  municipal
};
