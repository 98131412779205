export const region = {
  basic: {
    divWidthClass:
      "flex-col bg-cover bg-no-repeat pointer-events-auto xl:items-center xl:w-1000 xl:h-1500 xl:pt-4 md:w-1000 md:h-1500 md:pt-4 sm:w-1000 sm:h-1500 sm:pt-4 xs:w-1000 xs:pt-4 xs:h-1500 mobile:h-1500 mobile:w-full mobile:pt-4",
    mainDivClass:
      "h-hero p-4 xl:w-8/12 xl:flex-row mobile:w-full xl:justify-center md:justify-center",
    cardDivClass:
      "pb-10 m-0 md:h-1500 md:w-8/12 sm:w-5/12 xs:w-5/12 mobile:w-11/12",
    headerMarginClass: "mb-2",
    stationClass:
      "font-bold text-center md:text-4xl mobile:text-xl mobile:px-4",
    hotlineClass: "md:pb-4 md:text-3xl mobile:text-xs mobile:pb-4",
    directoryGroupClass: "md:gap-5 mobile:gap-10",
    directoryClass: " md:pb-2 md:text-3xl mobile:text-xs mobile:pb-4",
    directoryIconLeftClass: "mobile:w-10 md:w-14",
    directoryIconRightClass: "mobile:w-10 md:w-12",
    strokeClass: "0.4px",
    hotlineStroke: "0.4px",
    toggleMask: "opacity-0 h-1500 invisible",
  },
  downloadable: {
    divWidthClass: "pt-8 bg-cover w-5400 h-10800 pointer-events-none",
    mainDivClass: "border h-hero p-4 xl:w-8/12 mobile:w-full",
    cardDivClass: "h-10800 w-7/12 pb-32",
    stationClass: "text-center text-16xl font-bold",
    hotlineClass: "text-10xl font-bold pb-12",
    directoryGroupClass: "gap-40 items-center",
    directoryClass: "text-16xl font-bold mb-52",
    directoryIconLeftClass: "w-left",
    directoryIconRightClass: "w-right",
    toggleMask: "opacity-100 h-10800 visible cursor-not-allowed",
    strokeClass: "15px",
    hotlineStroke: "10px",
  },
};

export const city = {
  basic: {
    divWidthClass:
      "flex-col bg-cover bg-no-repeat pointer-events-auto xl:items-center xl:w-1000 xl:h-1500 xl:pt-4 md:w-1000 md:h-1500 md:pt-4 sm:w-1000 sm:h-1500 sm:pt-4 xs:w-1000 xs:pt-4 xs:h-1500 mobile:h-1500 mobile:w-full mobile:pt-4",
    mainDivClass:
      "h-hero p-4 xl:w-8/12 xl:flex-row mobile:w-full xl:justify-center md:justify-center",
    cardDivClass:
      "pb-10 m-0 md:h-1500 md:w-8/12 sm:w-5/12 xs:w-5/12 mobile:w-11/12",
    headerMarginClass: "mb-2",
    stationClass:
      "font-bold text-center md:text-4xl mobile:text-xl mobile:px-4",
    hotlineClass: "md:pb-6 md:text-3xl mobile:text-xs mobile:pb-4",
    directoryGroupClass: "md:gap-5 mobile:gap-10",
    directoryClass: " md:pb-2 md:text-3xl mobile:text-xs mobile:pb-4",
    directoryIconLeftClass: "mobile:w-10 md:w-14",
    directoryIconRightClass: "mobile:w-10 md:w-12",
    strokeClass: "0.4px",
    hotlineStroke: "0.4px",
    toggleMask: "opacity-0 h-1500 invisible",
  },
  downloadable: {
    divWidthClass: "pt-8 bg-cover w-5400 h-10800 pointer-events-none",
    mainDivClass: "border h-hero p-4 xl:w-8/12 mobile:w-full",
    cardDivClass: "h-10800 w-7/12 pb-32",
    stationClass: "text-center text-22xl font-bold",
    hotlineClass: "text-12xl font-bold pb-12",
    directoryGroupClass: "gap-40 items-center",
    directoryClass: "text-16xl font-bold mb-52",
    directoryIconLeftClass: "w-left",
    directoryIconRightClass: "w-right",
    toggleMask: "opacity-100 h-10800 visible cursor-not-allowed",
    strokeClass: "15px",
    hotlineStroke: "10px",
  },
};
export const municipal = {
  basic: {
    divWidthClass:
      "flex-col bg-cover bg-no-repeat pointer-events-auto xl:items-center xl:w-1000 xl:h-1500 xl:pt-4 md:w-1000 md:h-1500 md:pt-4 sm:w-1000 sm:h-1500 sm:pt-4 xs:w-1000 xs:pt-4 xs:h-1500 mobile:h-1500 mobile:w-full mobile:pt-4",
    mainDivClass:
      "h-hero p-4 xl:w-8/12 xl:flex-row mobile:w-full xl:justify-center md:justify-center",
    cardDivClass:
      "pb-10 m-0 md:h-1500 md:w-8/12 sm:w-5/12 xs:w-5/12 mobile:w-11/12",
    headerMarginClass: "mb-2",
    stationClass:
      "font-bold text-center md:text-4xl mobile:text-xl mobile:px-4",
    hotlineClass: "md:pb-6 md:text-3xl mobile:text-xs mobile:pb-4",
    directoryGroupClass: "md:gap-5 mobile:gap-10",
    directoryClass: " md:pb-2 md:text-3xl mobile:text-xs mobile:pb-4",
    directoryIconLeftClass: "mobile:w-10 md:w-14",
    directoryIconRightClass: "mobile:w-10 md:w-12",
    strokeClass: "0.4px",
    hotlineStroke: "0.4px",
    toggleMask: "opacity-0 h-1500 invisible",
  },
  downloadable: {
    divWidthClass: "pt-8 bg-cover w-5400 h-10800 pointer-events-none",
    mainDivClass: "border h-hero p-4 xl:w-8/12 mobile:w-full",
    cardDivClass: "h-10800 w-7/12 pb-32",
    stationClass: "text-center text-22xl font-bold",
    hotlineClass: "text-12xl font-bold pb-12",
    directoryGroupClass: "gap-40 items-center",
    directoryClass: "text-16xl font-bold mb-52",
    directoryIconLeftClass: "w-left",
    directoryIconRightClass: "w-right",
    toggleMask: "opacity-100 h-10800 visible cursor-not-allowed",
    strokeClass: "15px",
    hotlineStroke: "10px",
  },
};
export default { region, city, municipal };
