import Header from "./components/Header";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Create from "./Create";
import Footer from "./components/Footer";
import Canvas from "./components/Canvas";
function App() {
  return (
    <Router>
      <div className="flex flex-col w-full mobile:w-full justify-center items-center">
        <Header></Header>
        <div className="flex w-full md:pt-22 mobile:pt-18">
          <Routes>
            {/* <Route
              exact
              path="/"
              element={<Navigate to="/bfpdirectory/" replace />}
            ></Route> */}
            <Route exact path="/" element={<Home />} />
            <Route exact path="/create" element={<Create />} />
            {/* <Route
              exact
              path="/bfpdirectory/canvas"
              element={<Canvas />}
            />{" "} */}
          </Routes>
        </div>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
