import React from "react";
import heroImg from "./images/hero-img.jpg";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div className="flex w-screen">
      <div className="relative w-full">
        <div
          className="flex flex-row w-screen bg-cover bg-center  h-hero bg-fixed m-0 xl:p-16 mobile:p-0"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + heroImg})`,
          }}
        >
          <div className="flex justify-center ">
            <div className="flex flex-col 2xl:w-5/12 xl:w-6/12 lg:w-7/12 md:w-8/12 sm:w-9/12 items-start text-white p-10">
              <div className="flex flex-col z-10">
                <span className="sm:text-7xl font-thin mobile:text-5xl text-yellow-400">
                  Create
                </span>
                <span className="sm:text-7xl mobile:text-5xl font-semibold">
                  Directorate
                </span>
              </div>
              <div className="flex mt-6 z-10">
                <span className="sm:text-lg mobile:text-sm font-light ">
                  Maintains a consistent design and layout across all BFP Region
                  7, providing a seamless experience for users. Enforces
                  standardized position titles to maintain consistency and
                  clarity across all BFP Region 7 stations and offices.
                </span>
              </div>
              <div className="flex w-full justify-start mt-10 z-10">
                <div className="flex w-5/12 justify-center items-center ">
                  <Link
                    to="/create"
                    className="p-2 text-center w-full bg-yellow-500 border border-yellow-500 text-black hover:bg-transparent hover:border hover:text-white ease-in-out duration-500"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-hero bg-black opacity-70 absolute top-0 left-0 -z-1"></div>
      </div>
    </div>
  );
}

export default Home;
