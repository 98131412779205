import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div>
      <footer className="flex w-screen bg-slate-200 xl:px-20 py-10 xl:flex-row lg:flex-col mobile:flex-col mobile:px-4 xs:px-8">
        <div className="flex flex-col xl:w-6/12 lg:w-full sm:w-full">
          <div className="flex flex-col">
            <span className="text-lg font-bold">About Us</span>
            <div className="border-2 border-red-800 w-12 mt-1"></div>
          </div>
          <div className="flex flex-col mt-4 text-gray-800">
            <span className="w-full text-lg font-semibold  mb-2">
              BFP Region 7 - Standard Directorate
            </span>
            <span className="font-light xl:w-6/12 lg:w-full sm:w-full">
              A transparent and accessible directory can also increase public
              trust in the BFP's capabilities. The public can have confidence
              that the organization is organized and equipped with well-trained
              personnel to handle emergencies effectively
            </span>
          </div>
        </div>
        <div className="flex flex-col xl:w-6/12 xl:mt-0 lg:w-full sm:w-full lg:mt-4">
          <div className="flex flex-col">
            <span className="text-lg font-bold">Contact Us</span>
            <div className="border-2 border-red-800 w-12 mt-1"></div>
          </div>
          <div className="flex xl:flex-row lg:flex-col mobile:flex-col mt-4 text-gray-800">
            <div className="xl:w-6/12 lg:w-full mobile:w-screen flex flex-col border items-start ">
              <div className="w-8/12 flex mb-4">
                <div className="w-10 h-10 shrink-0 border ">
                  <FontAwesomeIcon icon={faLocationDot} size="lg" />
                </div>
                <div className="">
                  <span>
                    Natalio Bacalso Avenue, Pahina Central, Cebu City, Cebu
                  </span>
                </div>
              </div>
              <div className="w-8/12 flex  mb-4">
                <div className="w-10 h-10 shrink-0 border ">
                  <FontAwesomeIcon icon={faPhone} size="lg" />
                </div>
                <span>032 517-9027</span>
              </div>
            </div>
            <div className="flex xl:w-6/12 lg:w-full mobile:w-full">
              <div className="w-10 h-10 shrink-0 border ">
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </div>
              <div className="flex flex-col">
                <span>• admin.region7@bfp.gov.ph</span>
                <span>• itcur7@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
