import React, { useState, useEffect, useCallback } from "react";
import logo from "../images/logo.png";
import bfplogo from "../images/bfplogo.png";
import { Link } from "react-router-dom";

const FixedHeader = ({ isDownloadable, previewHandler, downloadImage }) => {
  const [menu, setMenu] = useState(false);
  const [myStyle, setMyStyle] = useState("hidden");
  const menuHandler = (e) => {
    e.preventDefault();
    if (menu) {
      setMyStyle("hidden ");
    } else {
      setMyStyle("flex ");
    }
    setMenu(!menu);
  };
  const handleWindowResize = useCallback((event) => {
    if (window.innerWidth > 767) {
      if (menu) {
        setMyStyle("hidden");
        setMenu(!menu);
      }
    }
  });
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  return (
    <div>
      {/* bg-gray-800 */}
      <header className="fixed w-screen bg-red-800 top-0 left-0 z-50 py-4 md:px-20 sm:px-8 mobile:px-4 shadow-md">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-top space-x-2">
            <div className="mobile:w-10 md:w-14">
              <img src={bfplogo} alt="" />
            </div>
            <div className="mobile:w-8 md:w-12">
              <img src={logo} alt="" className="w-full h-auto" />
            </div>
            <div className="flex flex-col space-y-0 ps-1 border-s-4 border-s-yellow-400">
              <span className="text-2xl  text-white md:text-xl font-semibold mobile:text-sm">
                Bureau of Fire Protection
              </span>
              <span className="text-xl text-white mobile:text-sm">
                REGION VII
              </span>
            </div>
          </div>
          <div className="flex justify-between space-x-4">
            <div className="flex space-x-2 text-gray-300 mobile:hidden sm:hidden md:flex">
              <Link to="/" className="border-black p-1 hover:text-white">
                Home
              </Link>
              <Link to="/create" className="border-black p-1 hover:text-white">
                Create
              </Link>
              {/* <Link to="/canvas" className="border-black p-1 hover:text-white">
                Canvas
              </Link> */}
            </div>
            <div className="flex justify-center items-center md:hidden">
              <button onClick={menuHandler}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      <div
        className={`${myStyle} w-6/12 fixed h-screen z-20 top-0 left-0 bg-red-900`}
      >
        <div className="w-full flex flex-col pt-24 px-2" onClick={menuHandler}>
          <Link
            to="/"
            className="  p-1 text-gray-300 hover:text-white hover:font-semibold rounded-md px-3 py-2 hover:bg-red-600"
          >
            Home
          </Link>
          <Link
            to="/create"
            className="  p-1 text-gray-300 hover:text-white hover:font-semibold rounded-md px-3 py-2 hover:bg-red-600"
          >
            Create
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FixedHeader;
