export const region = {
  basic: {
    cardMain: "rounded-md mobile:my-1 md:my-1",
    cardContainer: "rounded-md",
    cardImgContainer:
      " rounded-l-md md:w-5/12 mobile:w-regionMontainer mobile:h-regionMContainer md:w-regionContainer md:h-regionContainer",
    imageContainerClass:
      "mobile:w-regionMobile mobile:h-regionMobile md:w-regionFull md:h-regionFull",
    imageClass: "border-2",
    iconClass: "ms-1 md:w-4 md:h-4 mobile:w-2 mobile:h-2 ",
    controlContainer: "rounded-md",
    accountNumberClass: "md:text-base mobile:text-xs",
    designationClass: "md:text-sm mobile:text-xs",
  },

  downloadable: {
    cardMain: "rounded-3xl my-7",
    cardContainer: "rounded-3xl",
    cardImgContainer: "py-12 w-3/12 rounded-l-3xl",
    imageContainerClass: "w-regionDownload h-regionDownload",
    imageClass: "border-8",
    iconClass: "h-12 w-12 m-4",
    controlContainer: "rounded-3xl",
    accountNumberClass: "text-8xl",
    designationClass: "text-7xl",
  },
};

export const city = {
  basic: {
    cardMain: "rounded-md mobile:my-3 md:my-3",
    cardContainer: "rounded-md",
    cardImgContainer:
      " rounded-l-md md:w-5/12 mobile:w-cityMContainer mobile:h-cityMContainer md:w-cityContainer md:h-cityContainer",
    imageContainerClass:
      "mobile:w-cityMobile mobile:h-cityMobile md:w-cityFull md:h-cityFull",
    imageClass: "border-2",
    iconClass: "ms-1 md:w-4 md:h-4 mobile:w-2 mobile:h-2 ",
    controlContainer: "rounded-md",
    accountNumberClass: "md:text-lg mobile:text-xs",
    designationClass: "md:text-base mobile:text-xs",
  },

  downloadable: {
    cardMain: "rounded-3xl my-36",
    cardContainer: "rounded-3xl",
    cardImgContainer: "py-12 w-5/12 rounded-l-3xl",
    imageContainerClass: "w-cityDownload h-cityDownload",
    imageClass: "border-8",
    iconClass: "h-12 w-12 m-4",
    controlContainer: "rounded-3xl",
    accountNumberClass: "text-8xl",
    designationClass: "text-7xl",
  },
};

export const municipal = {
  basic: {
    cardMain: "rounded-md mobile:my-4 md:my-3",
    cardContainer: "rounded-md",
    cardImgContainer:
      " rounded-l-md md:w-5/12 mobile:w-cityMContainer mobile:h-cityMContainer md:w-cityContainer md:h-cityContainer",
    imageContainerClass:
      "mobile:w-cityMobile mobile:h-cityMobile md:w-cityFull md:h-cityFull",
    imageClass: "border-2",
    iconClass: "ms-1 md:w-4 md:h-4 mobile:w-2 mobile:h-2 ",
    controlContainer: "rounded-md",
    accountNumberClass: "md:text-lg mobile:text-xs",
    designationClass: "md:text-base mobile:text-xs",
  },

  downloadable: {
    cardMain: "rounded-3xl my-36",
    cardContainer: "rounded-3xl",
    cardImgContainer: "py-12 w-5/12 rounded-l-3xl",
    imageContainerClass: "w-cityDownload h-cityDownload",
    imageClass: "border-8",
    iconClass: "h-12 w-12 m-4",
    controlContainer: "rounded-3xl",
    accountNumberClass: "text-8xl",
    designationClass: "text-7xl",
  },
};

export default { region, city, municipal };
